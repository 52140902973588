import React from "react";
import DashbaordHome from "../../common/DashboardHome";

function SuperAdminHome() {
  return (
    <div className="mt-5">
      <DashbaordHome />
    </div>
  );
}

export default SuperAdminHome;
