import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../utils/api";
import { getNavigation } from "../utils/hooks";

function Navbar() {
  const navigate = useNavigate();

  const navLinks = [
    {
      label: "Dashboard",
      link: "/user",
    },
    {
      label: "Products",
      link: "/user/products",
    },
    {
      label: "Clubs",
      link: "/user/clubs",
    },
    {
      label: "Blogs",
      link: "/user/blogs",
    },
    {
      label: "Posts",
      link: "/user/posts",
    },
    {
      label: "Cart",
      link: "/user/cart",
    },
    {
      label: "Profile",
    },
  ];

  const RenderProfileNavLink = () => {
    return (
      <li className="nav-item dropdown ">
        <a
          className="nav-link dropdown-toggle d-flex align-items-center text-white"
          href="javascript.void(0)"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="material-symbols-outlined">account_circle</span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <p
              onClick={() => navigate("/user/profile")}
              className="dropdown-item"
              role={"button"}
            >
              My Profile
            </p>
          </li>
          {/* <li>
            <p
              onClick={() => navigate("/user/changePassword")}
              className="dropdown-item"
              role={"button"}
            >
              Change Password
            </p>
          </li> */}
          <li>
            <p role="button" className="dropdown-item mb-0" onClick={logout}>
              Logout
            </p>
          </li>
        </ul>
      </li>
    );
  };
  return (
    <nav className="navbar navbar-expand-lg bg-cyan-dark sticky-top ">
      <div className="container-fluid d-flex justify-content-center">
        <div className="justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            {navLinks.map((item, index) => (
              <li
                key={index}
                className="nav-item mx-3 d-flex align-items-center"
              >
                {item.label === "Profile" ? (
                  <RenderProfileNavLink />
                ) : item.label === "Cart" ? (
                  <NavLink className="nav-link text-white " to={item.link}>
                    <span className="material-symbols-outlined mt-1">
                      local_mall
                    </span>
                  </NavLink>
                ) : (
                  <NavLink className="nav-link text-white" to={item.link}>
                    {item.label}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

function StudentLayout({ children }) {
  React.useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "student") {
      getNavigation(role);
    }
  }, []);

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default StudentLayout;
