import React from "react";

function Card(props) {
  return (
    <div className="card">
      <div className="text-center">
        <img
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
          alt="sercie"
          src={require(`../assets/${props.category}-${props.index + 1}.jpg`)}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title text-info">{props.title}</h5>
        <p className="card-text">{props.description}</p>
      </div>
    </div>
  );
}

export default Card;
