import React from "react";
import { useNavigate } from "react-router";
import api from "../utils/api";
import { toast } from "react-toastify";
import { getNavigation } from "../utils/hooks";

function Auth() {
  const [view, setView] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const AuthWrapper = ({ children }) => {
    return (
      <section className="text-center card rounded col-10 col-md-5 p-5">
        {children}
      </section>
    );
  };

  const LoginForm = () => {
    const [form, setForm] = React.useState({
      email: "",
      password: "",
    });

    const LoginAction = async () => {
      if (!form.email || !form.password) {
        return;
      }

      setLoading(true);
      try {
        const response = await api({
          URL: "login",
          METHOD: "POST",
          BODY: form,
        });
        toast[response.status === 200 ? "success" : "error"](response.message);
        if (response.status === 200) {
          const { id, role } = response.user;
          getNavigation(role);
          localStorage.setItem("token", response.token);
          localStorage.setItem("userId", id);
          localStorage.setItem("role", role);
        }
        setLoading(false);
      } catch (error) {
        toast["error"](error.message);
      }
      setLoading(false);
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          LoginAction();
        }}
      >
        <div className="mb-4">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            minLength={8}
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button
            disabled={loading}
            type="submit"
            className="btn btn-primary px-5 py-2 w-100"
          >
            {loading ? "Please wait.." : "Submit"}
          </button>
        </div>
      </form>
    );
  };

  const RegisterForm = () => {
    const [form, setForm] = React.useState({
      name: "",
      email: "",
      password: "",
      phone: "",
      role: "student",
    });

    const RegisterAction = async () => {
      if (!form.email || !form.password || !form.name || !form.phone) {
        return toast["error"]("Please enter all fields");
      }

      setLoading(true);
      try {
        const response = await api({
          URL: "register",
          METHOD: "POST",
          BODY: form,
        });
        toast[response.status === 200 ? "success" : "error"](response.message);
      } catch (error) {
        console.log(error.response.message);
      }
      setLoading(false);
    };

    const handleChangeInput = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          RegisterAction();
        }}
      >
        <div className="mb-4">
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
            placeholder="Name"
          />
        </div>
        <div className="mb-4">
          <input
            type="tel"
            className="form-control"
            name="phone"
            onChange={handleChangeInput}
            value={form.phone}
            placeholder="Phone"
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={handleChangeInput}
            value={form.email}
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <input
            value={form.password}
            type="password"
            name="password"
            onChange={handleChangeInput}
            className="form-control"
            placeholder="Password"
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button
            disabled={loading}
            type="submit"
            className="btn btn-primary px-5 py-2 w-100"
          >
            {loading ? "Please wait.." : "Submit"}
          </button>
        </div>
      </form>
    );
  };

  return (
    <main>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Login / Register</h3>
        </div>
      </div>
      <div className="container d-flex justify-content-center py-5">
        <AuthWrapper>
          <h3>{view ? "Sign in" : "Sign Up"}</h3>
          <p className="mt-4">Sign in for better experience</p>

          {view ? <LoginForm /> : <RegisterForm />}

          {view && (
            <p
              className="mt-4 text-center"
              role="button"
              onClick={() => navigate("/resetPassword")}
            >
              Reset password{" "}
            </p>
          )}
          <p
            onClick={() => setView(!view)}
            className="mt-3 text-center"
            role="button"
          >
            {view ? "Sign Up" : "Already user? Sign in"}
          </p>
        </AuthWrapper>
      </div>
    </main>
  );
}

export default Auth;
