import React, { useState } from "react";
import QueryItem from "../../common/QueryItem";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function SuperAdminQueries() {
  const { data, fetchFunc } = useFetch("chat");
  const { callFunc } = usePost();
  const [show, setshow] = useState("");
  const [message, setMessage] = useState("");

  const SendMessage = (receiverId) => {
    if (message) {
      const body = {
        senderId: 1,
        receiverId,
        message,
      };
      callFunc("chat", "POST", body);
      setMessage("");
      fetchFunc();
    }
  };

  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3>Open queries</h3>
        {data &&
          data.map((x, i) => (
            <div className="py-2" key={i}>
              <QueryItem
                name={x.sender.name}
                index={i}
                setshow={setshow}
                title={x.message}
              />
              {show === i && (
                <div className="mt-4 p-2 d-flex input-group">
                  <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter message"
                  />
                  <button
                    onClick={() => SendMessage(x.senderId)}
                    className="btn btn-dark"
                    type="button"
                    id="button-addon2"
                  >
                    Send
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

export default SuperAdminQueries;
