import React from "react";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import ProductCard from "../../common/ProductCard";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function StudentProducts() {
  const { data = [], loading } = useFetch("product");

  const { callFunc } = usePost();

  return (
    <div className="mt-5">
      <div className="container">
        <h3>Products</h3>
        {loading ? (
          <Loader />
        ) : (
          <div className="d-flex flex-wrap">
            {data && data.length > 0 ? (
              data.map((x, i) => (
                <div className="p-4 col-12 col-md-4" key={i}>
                  <ProductCard
                    title={x.title}
                    image={x.image}
                    description={x.description}
                    category="product"
                    index={i}
                    cost={x.cost}
                    onAddItemtoCart={(quantity) =>
                      callFunc("cart", "POST", {
                        userId: localStorage.getItem("userId"),
                        productId: x.id,
                        quantity,
                      })
                    }
                  />
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentProducts;
