import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../utils/api";
import usePost from "../utils/usePost";
import Loader from "./Loader";
import UsersTable from "./UsersTable";

function UsersList({ users, loading, label, role, fetchFunc }) {
  const [show, setShow] = useState(false);
  const [loadingg, setLoading] = useState(false);
  const { callFunc } = usePost();

  const updateStatus = (userId, status) => {
    callFunc(`user/${userId}`, "POST", {
      status,
    });
    fetchFunc();
  };

  const AddUser = () => {
    const [form, setForm] = React.useState({
      name: "",
      email: "",
      password: "",
      phone: "",
      role,
    });

    const RegisterAction = async () => {
      if (!form.email || !form.password || !form.name || !form.phone) {
        return toast["error"]("Please enter all fields");
      }

      setLoading(true);
      try {
        const response = await api({
          URL: "register",
          METHOD: "POST",
          BODY: form,
        });
        toast[response.status === 200 ? "success" : "error"](response.message);
        setShow(!show);
        fetchFunc();
      } catch (error) {
        console.log(error.response.message);
      }
      setLoading(false);
    };

    const handleChangeInput = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          RegisterAction();
        }}
      >
        <div className="mb-4">
          <input
            type="text"
            className="form-control"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
            placeholder="Name"
          />
        </div>
        <div className="mb-4">
          <input
            type="tel"
            className="form-control"
            name="phone"
            onChange={handleChangeInput}
            value={form.phone}
            placeholder="Phone"
            maxLength={10}
          />
        </div>
        <div className="mb-4">
          <input
            type="email"
            className="form-control"
            name="email"
            onChange={handleChangeInput}
            value={form.email}
            placeholder="Email"
          />
        </div>
        <div className="mb-4">
          <input
            value={form.password}
            type="password"
            name="password"
            onChange={handleChangeInput}
            className="form-control"
            placeholder="Password"
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button
            disabled={loadingg}
            type="submit"
            className="btn btn-primary px-5 py-2 w-100"
          >
            {loadingg ? "Please wait.." : "Submit"}
          </button>
        </div>
      </form>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <h3 className="text-primary">{label}</h3>
        <button
          className={`btn btn-${show ? "danger" : "primary"}`}
          onClick={() => setShow(!show)}
        >
          {show ? "X Close" : "Add User"}
        </button>
      </div>{" "}
      {loading ? (
        <Loader />
      ) : show ? (
        <AddUser />
      ) : (
        users && (
          <div className="py-2">
            <UsersTable data={users} updateStatus={updateStatus} />
          </div>
        )
      )}
    </div>
  );
}

export default UsersList;
