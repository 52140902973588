import React from "react";
import { useNavigate } from "react-router";

function OrdersTable({ data }) {
  const navigate = useNavigate();
  return (
    <div>
      <table className="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <td>Order Status</td>
            <td>Order Total</td>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((x, i) => (
            <tr key={i}>
              <th scope="row">{x.user.name}</th>
              <td>{x.user.email}</td>
              <td>{x.user.phone}</td>
              <td>{x.status}</td>
              <td>$ {x.totalCost}</td>
              <td className="d-flex">
                <button
                  onClick={() => navigate(`/business-owner/orders/${x.id}`)}
                  className="m-2 btn btn-secondary"
                >
                  Manage
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrdersTable;
