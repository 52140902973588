import React, { useState, useEffect } from "react";
import usePost from "../utils/usePost";
import useFetch from "../utils/useFetch";
import Loader from "./Loader";
import { toast } from "react-toastify";
import api from "../utils/api";

function Chatbox() {
  const senderId = localStorage.getItem("userId");
  const [users, setUsers] = useState([]);
  const [show, setShow] = React.useState(false);
  const [userLoading, setloading] = React.useState(false);
  const [message, setMessage] = useState("");
  const { callFunc } = usePost();
  const [selected, setSelected] = useState({ id: "", name: "" });
  const { data, loading, fetchFunc } = useFetch(
    `chat/${selected.id}/${senderId}`
  );

  const SendMessage = () => {
    if (message) {
      const body = {
        senderId,
        receiverId: selected.id,
        message,
      };
      callFunc("chat", "POST", body);
      setMessage("");
      fetchFunc();
    }
  };

  useEffect(() => {
    fetchUnreadChats();

    return () => {
      setSelected({ id: "", name: "" });
    };
  }, []);

  useEffect(() => {
    fetchChats();
  }, [selected]);

  const fetchChats = async (value) => {
    setloading(true);
    try {
      const { data } = await api({
        METHOD: "GET",
        URL: `chats/${senderId}`,
      });
      setUsers(data);
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const fetchUnreadChats = async (value) => {
    setloading(true);
    try {
      const { data } = await api({
        METHOD: "GET",
        URL: `chats/unread/${senderId}`,
      });
      if (data.length === 0) {
        fetchChats();
      } else {
        setUsers(data);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  const searchUsers = async (value) => {
    if (value.length < 2) {
      return;
    }
    setloading(true);
    try {
      const { data } = await api({
        METHOD: "GET",
        URL: `users?search=${value}`,
      });
      const formated = data.map((x) => {
        return {
          ...x,
          sender: {
            name: x.name,
            id: x.id,
          },
        };
      });
      setUsers(formated);
    } catch (error) {
      toast.error(error.message);
    }
    setloading(false);
  };

  return (
    <div
      className="bg-white shadow border border-info rounded-top"
      style={{
        position: "fixed",
        bottom: 0,
        right: 10,
        width: 300,
        zIndex: 99,
      }}
    >
      <div>
        <div
          role={"button"}
          className="bg-cyan-light p-3"
          onClick={() => setShow(!show)}
        >
          Chat
        </div>
        {show && (
          <div>
            {selected.id ? (
              <>
                <div
                  style={{ height: 300 }}
                  className="position-relative overflow-scroll pb-5"
                >
                  <div className="p-2 bg-light shadow d-flex position-sticky top-0">
                    <img
                      alt="back-icon"
                      onClick={() => setSelected({ id: "", name: "" })}
                      role={"button"}
                      src="https://png.pngtree.com/png-vector/20190412/ourlarge/pngtree-vector-back-icon-png-image_931209.jpg"
                      height={25}
                    />{" "}
                    <div className="mx-3">{selected.name}</div>
                  </div>
                  {loading ? (
                    <Loader />
                  ) : (
                    data &&
                    data.map((x, i) => (
                      <div
                        key={i}
                        className={`p-3 d-flex ${
                          x.senderId === Number(senderId)
                            ? "justify-content-end"
                            : ""
                        }`}
                      >
                        <div
                          style={{ height: 30, width: 30, borderRadius: 50 }}
                          className="bg-info d-flex align-items-center justify-content-center"
                        >
                          {x.sender.name.split("")[0]}
                        </div>
                        <div className="mx-2">
                          <p className="mb-0 bg-light px-3 py-2 rounded border border-info">
                            {x.message}
                          </p>
                          <small className="m-2">
                            {x.created_at.split("T")[1].split(".")[0]}
                          </small>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <div className="p-2 d-flex position-sticky top-0 input-group">
                  <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Enter message"
                  />
                  <button
                    onClick={SendMessage}
                    className="btn btn-dark"
                    type="button"
                    id="button-addon2"
                  >
                    Send
                  </button>
                </div>
              </>
            ) : (
              <div style={{ height: 300 }}>
                <div className="input-group position-sticky top-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search users"
                    onChange={(e) => searchUsers(e.target.value)}
                  />
                </div>
                {userLoading ? (
                  <Loader />
                ) : users && users.length > 0 ? (
                  users.map((x, i) => (
                    <li
                      role={"button"}
                      onClick={() => {
                        setSelected({ name: x.sender.name, id: x.sender.id });
                        fetchUnreadChats();
                      }}
                      key={i}
                      className="p-3 border d-flex align-items-center justify-content-between"
                    >
                      {x.sender.name}

                      <span className="badge rounded-pill text-bg-warning">
                        {x.unread}
                      </span>
                    </li>
                  ))
                ) : (
                  <h5 className="mt-5 text-center">No Recent Chats</h5>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Chatbox;
