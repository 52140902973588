import React from "react";
import { toast } from "react-toastify";
import ClubCard from "../../common/ClubCard";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function StudentClubs() {
  const { data = [], loading, error, fetchFunc } = useFetch("club");
  const { callFunc, loading: postLoading } = usePost();
  const [show, setShow] = React.useState(false);
  const userId = localStorage.getItem("userId");

  React.useEffect(() => {
    return () => {
      setShow(false);
    };
  }, []);

  if (error) {
    return <h4>Something went wrong, Please try again</h4>;
  }

  const AddClub = () => {
    const [form, setForm] = React.useState({
      title: "",
      image: "",
      description: "",
    });

    const handleChangeInput = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };

    const handleSubmit = () => {
      if (!form.title || !form.description || !form.image) {
        return toast.error("Please enter all fields");
      }

      callFunc("club", "POST", {
        ...form,
        userId,
        users: JSON.stringify([Number(userId)]),
        status: "Active",
      });
      setForm({
        title: "",
        image: "",
        description: "",
      });
      setShow(false);
      fetchFunc();
    };

    return (
      <div className="col-6">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Club
              </h1>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label for="recipient-name" className="col-form-label">
                    Title:
                  </label>
                  <input
                    value={form.title}
                    onChange={handleChangeInput}
                    name="title"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label for="recipient-name" className="col-form-label">
                    Image URL:
                  </label>
                  <input
                    value={form.image}
                    onChange={handleChangeInput}
                    name="image"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label for="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    name="description"
                    value={form.description}
                    onChange={handleChangeInput}
                    className="form-control"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setShow(false)}
                type="button"
                className="btn btn-secondary m-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={postLoading}
                type="button"
                className="btn btn-primary"
              >
                {postLoading ? "Please wait.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="d-flex justify-content-between">
          <h3>Clubs</h3>
          <button
            onClick={() => setShow(true)}
            className="btn btn-outline-success"
          >
            + Add Club
          </button>
        </div>{" "}
        <div className="d-flex flex-wrap">
          {loading ? (
            <Loader />
          ) : show ? (
            <AddClub />
          ) : data && data.length > 0 ? (
            data.map((x, i) => (
              <div className="p-4 col-12 col-md-4" key={i}>
                <ClubCard {...x} fetchFunc={fetchFunc} />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentClubs;
