import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();

  const navLinks = [
    {
      label: "About Us",
      link: "/about",
    },
    {
      label: "Blogs",
      link: "/blogs",
    },
    {
      label: "Our Services",
      link: "/services",
    },
    {
      label: "Contact Us",
      link: "/contact",
    },
    {
      label: "Login / Register",
      link: "/login",
    },
  ];

  const RenderProfileNavLink = () => {
    return (
      <li className="nav-item dropdown ">
        <a
          className="nav-link dropdown-toggle d-flex align-items-center text-white"
          href="javascript.void(0)"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Home
        </a>
        <ul className="dropdown-menu">
          <li>
            <a
              className="dropdown-item "
              href="#"
              onClick={() => navigate("/user")}
            >
              Student
            </a>
          </li>
          <li>
            <a
              className="dropdown-item "
              href="#"
              onClick={() => navigate("/business-owner")}
            >
              Business Owner
            </a>
          </li>
          <li>
            <a
              className="dropdown-item "
              href="#"
              onClick={() => navigate("/school-admin")}
            >
              School Admin
            </a>
          </li>
          <li>
            <a
              className="dropdown-item "
              href="#"
              onClick={() => navigate("/super-admin")}
            >
              Super Admin
            </a>
          </li>
        </ul>
      </li>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg bg-cyan-dark sticky-top">
      <div className="container">
        <NavLink className="navbar-brand fw-bold text-white" to={"/"}>
          Mercado Escolar
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            {navLinks.map((item, index) => (
              <li key={index} className="nav-item mx-3">
                <NavLink className="nav-link text-white" to={item.link}>
                  {" "}
                  {item.label}
                </NavLink>
              </li>
            ))}
            {/* <RenderProfileNavLink /> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

function MainLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default MainLayout;
