import React from "react";
import UsersList from "../../common/UsersList";
import useFetch from "../../utils/useFetch";

function SuperAdminUsers() {
  const { data, loading, error, fetchFunc } = useFetch("users");
  const users = data && data.filter((x) => x.role === "student");
  if (error) {
    return <h4>Something went wrong, Please try again</h4>;
  }

  return (
    <div className="mt-5">
      <div className="container col-6">
        <UsersList
          role="student"
          label="Students"
          users={users}
          loading={loading}
          fetchFunc={fetchFunc}
        />
      </div>
    </div>
  );
}

export default SuperAdminUsers;
