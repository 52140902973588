import React from "react";
import UsersList from "../../common/UsersList";
import useFetch from "../../utils/useFetch";

function SuperAdminSchools() {
  const { data, loading, error, fetchFunc } = useFetch("users");
  const users = data && data.filter((x) => x.role === "school_admin");
  console.log(users);
  if (error) {
    return <h4>Something went wrong, Please try again</h4>;
  }
  return (
    <div className="mt-5">
      <div className="container col-6">
        <UsersList
          role="school_admin"
          label={"School Admin"}
          users={users}
          loading={loading}
          fetchFunc={fetchFunc}
        />
      </div>
    </div>
  );
}

export default SuperAdminSchools;
