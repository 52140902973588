import React from "react";

function CartItem(props) {
  return (
    <div className="card">
      <div className="card-body px-4 d-flex justify-content-between">
        <div>
          <h5 className="card-title mb-0">{props.title}</h5>
          <div className="d-flex">
            <small className="text-secondary">Qty:</small>
            <p className="card-text mb-0 mx-1">{props.quantity}</p>
          </div>
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <p className="card-text mb-0" style={{ marginRight: "1.5rem" }}>
            $ {props.cost}
          </p>
          {props.onDeleteItem && (
            <button onClick={props.onDeleteItem} className="btn btn-danger">
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartItem;
