import React from "react";
import DashbaordHome from "../../common/DashboardHome";
import { shcoolAdminHome } from "../../utils/constants";

function SchoolAdminHome() {
  return (
    <div className="mt-5">
      <DashbaordHome cardData={shcoolAdminHome} />
    </div>
  );
}

export default SchoolAdminHome;
