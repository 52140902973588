import React from "react";
import usePost from "../utils/usePost";

function ClubCard(props) {
  const userId = localStorage.getItem("userId");
  const role = localStorage.getItem("role");
  const { callFunc } = usePost();

  const stausText = (y) => {
    if (y.users) {
      return String(y.userId) === userId || role === "school_admin"
        ? "delete"
        : y.users &&
          typeof y.users === "object" &&
          y.users.find((usr) => usr && String(usr.id) === userId)
        ? "leave"
        : "join";
    }
  };

  const JoinClub = (item) => {
    const users = item.users.map((x) => x.id);
    const body = {
      title: item.title,
      description: item.description,
      image: item.image,
      userId,
      users: JSON.stringify([...users, Number(userId)]),
      status: "Active",
    };
    callFunc(`club/${item.id}`, "POST", body);
    props.fetchFunc();
  };

  const LeaveClub = (item) => {
    const users = item.users
      .filter((x) => x.id !== Number(userId))
      .map((x) => x.id);
    const body = {
      title: item.title,
      description: item.description,
      image: item.image,
      userId,
      users: JSON.stringify(users),
      status: "Active",
    };
    callFunc(`club/${item.id}`, "POST", body);
    props.fetchFunc();
  };

  const DeleteClub = (item) => {
    callFunc(`club/${item.id}`);
    props.fetchFunc();
  };

  return (
    <div className="card">
      {props.image && (
        <div className="text-center">
          <img
            style={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
            }}
            alt="sercie"
            src={props.image}
          />
        </div>
      )}
      <div className="card-body">
        <h5 className="card-title text-info">{props.title}</h5>
        <p className="card-text">{props.description}</p>
        {props.users ? (
          <button
            className={`btn text-uppercase px-4 btn-${
              stausText(props) === "join"
                ? "success"
                : stausText(props) === "leave"
                ? "warning"
                : "primary"
            } mt-2`}
            onClick={() =>
              stausText(props) === "join"
                ? JoinClub(props)
                : stausText(props) === "leave"
                ? LeaveClub(props)
                : stausText(props) === "delete"
                ? DeleteClub(props)
                : null
            }
          >
            {stausText(props)}
          </button>
        ) : (
          <button className="mt-2 btn btn-info">Learn more</button>
        )}
      </div>
    </div>
  );
}

export default ClubCard;
