import React, { Fragment } from "react";
import CartItem from "../../common/CartItem";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import UserRow from "../../common/UserRow";
import useFetch from "../../utils/useFetch";

function StudentProfile() {
  const userId = localStorage.getItem("userId");
  const { data, loading } = useFetch("users");
  const { data: orders, loading: OrderLoading } = useFetch(
    `order?userId=${userId}`
  );
  const currentUser = (data && data.find((x) => String(x.id) === userId)) || {};

  return (
    <div className="mt-5">
      <UserRow loading={loading} currentUser={currentUser} />

      <div className="container mb-5">
        <h3>Past Orders</h3>
        <div className="mb-4">
          {OrderLoading ? (
            <Loader />
          ) : orders && orders.length > 0 ? (
            orders.map((x, i) => (
              <div className="border p-3 col-6 mb-4" key={i}>
                <div className="d-flex justify-content-between">
                  <h5>Order ID #{x.id}</h5>
                  <small>
                    Created at {x.created_at.split(".")[0].split("T")[0]}
                  </small>
                </div>
                <div className="mt-3">
                  {x.products.map((y, j) => (
                    <Fragment key={j}>
                      <CartItem title={y.title} quantity={1} cost={y.cost} />
                    </Fragment>
                  ))}
                  <div className="d-flex justify-content-around">
                    <div className="mt-4">
                      <small>Total Cost</small>
                      <p>$ {x.totalCost}</p>
                    </div>

                    <div className="mt-4">
                      <small>Order Status</small>
                      <p>{x.status}</p>
                    </div>

                    <div className="mt-4">
                      <small>Last Updated at</small>
                      <p>{x.updated_at.split(".")[0].split("T")[0]}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>

      {/* <div className="container mb-5">
        <h3>Products</h3>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"product"} index={i} />
            </div>
          ))}
        </div>
      </div> */}

      {/* <div className="container mb-5">
        <h3>Posts</h3>
        <div className="d-flex flex-wrap">
          {Array(4)
            .fill(0)
            .map((x, i) => (
              <div className="p-3 col-12 col-md-3" key={i}>
                <DashboardCard />
              </div>
            ))}
        </div>
      </div> */}

      {/* <div className="container mb-5">
        <h3>Clubs</h3>
        <div className="d-flex flex-wrap">
          {clubs.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"club"} index={i} />
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default StudentProfile;
