import React from "react";
import BusinessCards from "../../common/BusinessCards";

function BusinessOwnerBusiness() {
  return (
    <div className="mt-5">
      <div className="container">
        <h3>Business</h3>
        <BusinessCards />
      </div>
    </div>
  );
}

export default BusinessOwnerBusiness;
