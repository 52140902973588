import React from "react";
import Card from "../common/card";

function Services() {
  const serviceList = [
    {
      title: "Free Delivery",
      description: "We provide free delivery to the users on a minimum order of $100."
    },
    {
      title: "Exciting offers",
      description: "Get exciting offers from time to time on our webite for selected products."
    },
    {
      title: "Easy payment options",
      description: "Our system accepts multiple payment options and you can choose the most easiest one."
    }
  ];
  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold"> Services</h3>
        </div>
      </div>
      <div className="container my-5">
        <div className="d-flex flex-wrap">
          {serviceList.map((x, i) => (
            <div className="p-4 col-12 col-md-4" key={i}>
              <Card title={x.title} description={x.description} category="service" index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
