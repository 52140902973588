import React from "react";
import { NavLink } from "react-router-dom";
import { logout } from "../utils/api";
import { getNavigation } from "../utils/hooks";

function Navbar() {
  const navLinks = [
    {
      label: "Dashboard",
      link: "/super-admin",
    },
    {
      label: "School Admins",
      link: "/super-admin/schools",
    },
    {
      label: "Students",
      link: "/super-admin/users",
    },
    {
      label: "Posts",
      link: "/super-admin/posts",
    },
    // {
    //   label: "Resolve Queries",
    //   link: "/super-admin/queries",
    // },
  ];

  return (
    <nav className="navbar navbar-expand-lg bg-cyan-dark sticky-top ">
      <div className="container-fluid d-flex justify-content-center">
        <div className="justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            {navLinks.map((item, index) => (
              <li
                key={index}
                className="nav-item mx-3 d-flex align-items-center "
              >
                <NavLink className="nav-link text-white" to={item.link}>
                  {item.label}
                </NavLink>
              </li>
            ))}
            <li className="nav-item mx-3 d-flex align-items-center ">
              <a
                href="/"
                className="dropdown-item nav-link text-white cursor-pointer"
                onClick={logout}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function SuperAdminLayout({ children }) {
  React.useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "super_admin") {
      getNavigation(role);
    }
  }, []);

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default SuperAdminLayout;
