import React from "react";
import useFetch from "../utils/useFetch";
import DashboardCard from "./DashboardCard";
import { LineChart } from "./LineChart";
import Loader from "./Loader";

function DashbaordHome() {
  const role = localStorage.getItem("role");
  const { data = [], loading } = useFetch(`analytics/${role}`);

  const stats = data && data.stats;
  const charts = data && data.charts;
  const values = data ? Object.values(stats) : [];
  const Keys = data ? Object.keys(stats) : [];

  const chartCount = data ? Object.keys(charts) : [];

  return loading ? (
    <Loader />
  ) : (
    <div className="mt-5">
      <div className="container d-flex flex-wrap">
        {Keys.map((x, i) => (
          <div className="p-3 col-12 col-md-3" key={i}>
            <DashboardCard title={x} count={values[i]} />
          </div>
        ))}
      </div>

      <div className="container mt-5">
        <div className="row justify-content-between">
          {chartCount.map((x, i) => (
            <div className="col-12 col-md-5 mb-5">
              <LineChart
                mainLabel={x}
                labels={charts[x].labels}
                label={charts[x].title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashbaordHome;
