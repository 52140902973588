import React from "react";
import { Navigate, useNavigate } from "react-router";
import CartItem from "../../common/CartItem";
import Loader from "../../common/Loader";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function StudentCart() {
  const userId = localStorage.getItem("userId");
  const { data = [], loading, fetchFunc } = useFetch(`cart/${userId}`);
  const { callFunc } = usePost();
  const navigate = useNavigate();

  const createOrder = () => {
    const body = {
      userId: Number(userId),
      products: JSON.stringify(data.map((x) => x.productId)),
      quantities: JSON.stringify(data.map((x) => x.quantity)),
      totalCost: getTotal(),
      status: "Order Placed",
    };
    callFunc("order", "POST", body);
    data.map((x) => {
      callFunc(`cart/${x.id}`);
    });
    setTimeout(() => {
      navigate("/user/profile");
    }, 2000);
  };

  if (loading) {
    return <Loader />;
  }
  let total_cost = 0;

  const getTotal = () => {
    const items = [...data];

    if (items) {

      for (let i = 0; i < items.length; ++i) {
        total_cost += items[i].quantity * items[i].product.cost;
      }
      return Math.round(total_cost);
    }
  };

  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3 className="text-primary">My Cart</h3>
        {data &&
          data.map((x, i) => (
            <div className="py-2" key={i}>
              <CartItem
                title={x.product.title}
                quantity={x.quantity}
                cost={x.product.cost}
                onDeleteItem={() => {
                  callFunc(`cart/${x.id}`);
                  fetchFunc();
                }}
              />
            </div>
          ))}
        {data && data.length > 0 ? (
          <div className="px-2 d-flex justify-content-between align-items-center mt-3">
            <div>
              <button
                onClick={createOrder}
                type="submit"
                className="btn btn-primary px-5"
              >
                Checkout
              </button>
            </div>
            <div>
              <span>Total</span>
              <p className="">$ {getTotal()}</p>
            </div>
          </div>
        ) : (
          <h4 className="text-center mt-5">Cart is Empty, Keep Shopping</h4>
        )}
      </div>
    </div>
  );
}

export default StudentCart;
