import React from "react";

function ChangePassword() {
  const ResetForm = () => {
    return (
      <form>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="Old Password"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="New Password"
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="Re type New Password"
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button type="submit" className="btn btn-primary px-5 py-2 w-100">
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Change Password</h3>
        </div>
      </div>
      <div className="container d-flex justify-content-center py-5">
        <section className="text-center card rounded col-10 col-md-5 p-5">
          <ResetForm />
        </section>
      </div>
    </div>
  );
}

export default ChangePassword;
