import React from "react";
import { businessOwnerHome } from "../utils/constants";
import useFetch from "../utils/useFetch";
import DashboardCard from "./DashboardCard";
import Loader from "./Loader";

function BusinessCards() {
  const role = localStorage.getItem("role");
  const { data = [], loading } = useFetch(`analytics/${role}`);
  const stats = data && data.stats;
  const values = data ? Object.values(stats) : [];

  return (
    <div className="d-flex flex-wrap">
      {loading ? (
        <Loader />
      ) : (
        businessOwnerHome.map((x, i) => (
          <div className="p-3 col-12 col-md-3" key={i}>
            <DashboardCard title={x.name} count={values[i]} />
          </div>
        ))
      )}
    </div>
  );
}

export default BusinessCards;
