import React, { useState } from "react";
import { toast } from "react-toastify";
import api from "../utils/api";
import usePost from "../utils/usePost";

function ResetPassword() {
  const [show, setShow] = useState(true);
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");

  const resetEmail = async () => {
    if (!email) {
      return;
    }
    const body = {
      email,
    };
    setloading(true);
    try {
      await api({
        URL: `forgot-password`,
        METHOD: "POST",
        BODY: body,
      });
      setShow(!show);
    } catch (e) {
      setloading(false);

      toast.error(e.message);
    }
    setloading(false);
  };

  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Reset Password</h3>
        </div>
      </div>
      <div className="container d-flex justify-content-center py-5">
        <section className="text-center card rounded col-10 col-md-5 p-5">
          {show ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                resetEmail();
              }}
            >
              <div className="mb-4">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                />
              </div>

              <div className="d-flex  justify-content-center">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary px-5 py-2 w-100"
                >
                  {loading ? "Please wait.." : "Submit"}
                </button>
              </div>
            </form>
          ) : (
            <div>
              <h4 className="text-primary">
                {" "}
                A Verification link has been sent to your email account
              </h4>
              <hr className="my-0" />
              <p className="mt-3">
                Please click on the link that has just been sent to your email
                account to verify your email and continue the registration
                process
              </p>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default ResetPassword;
