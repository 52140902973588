import React from "react";
import ClubCard from "../../common/ClubCard";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import ProductCard from "../../common/ProductCard";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function StudentHome() {
  const { data = [], loading } = useFetch("explore");
  const { fetchFunc } = useFetch("club");
  const { callFunc } = usePost();
  const clubs = data && data.clubs;
  const products = data && data.products;

  return loading ? (
    <Loader />
  ) : (
    <div className="mt-5">
      <div className="container">
        <h4>Explore Products</h4>
        <div className="d-flex flex-wrap">
          {products && products.length > 0 ? (
            products.map((x, i) => (
              <div className="p-3 col-12 col-md-3" key={i}>
                <ProductCard
                  title={x.title}
                  image={x.image}
                  description={x.description}
                  category="product"
                  index={i}
                  cost={x.cost}
                  onAddItemtoCart={(quantity) =>
                    callFunc("cart", "POST", {
                      userId: localStorage.getItem("userId"),
                      productId: x.id,
                      quantity,
                    })
                  }
                />{" "}
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>

      <div className="container mt-5">
        <h4>Explore Clubs</h4>
        <div className="d-flex flex-wrap">
          {clubs && clubs.length > 0 ? (
            clubs.map((x, i) => (
              <div className="p-3 col-12 col-md-3" key={i}>
                <ClubCard {...x} fetchFunc={fetchFunc} />
              </div>
            ))
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentHome;
