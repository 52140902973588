import React from "react";

function HeroSection() {
  return (
    <div className="bg-cyan-light py-5">
      <div className="text-center">
        <p>This is your college marketplace</p>
        <h3 className="fw-bold mt-3">Welcome to a new shopping</h3>
        <h3 className="fw-bold">experience!</h3>

        <div className="mt-5 d-flex justify-content-center">
          <div className="col-7 col-md-3">
            <div className="input-group mb-3 ">
              <input
                type="text"
                className="form-control"
                placeholder="Search product"
              />
              <button className="btn btn-dark" type="button" id="button-addon2">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Home() {
  return (
    <main className="mb-5">
      <HeroSection />
      <article className="container mt-5">
        People should not be afraid of shopping online. I think it will be the
        only way we will do our shopping in the future! Yesterday, I bought a
        birthday present for my mother, and it arrived today! That saves me a
        lot of time. I am a very busy person, and I am a little forgetful too.
        If I think of something I need, then it is wonderful that I can buy it
        at that moment.
      </article>
      <section className="container mt-5 d-flex flex-wrap align-items-center justify-content-between">
        <div className="col-12 col-md-5">
          <h4 className="fw-bold">Shopping with ease at your place</h4>
          <p>
            Shopping is a necessary part of life which very few people can
            avoid. It is a daily routine for some people. Some people shop for
            pleasure while other people go shopping just to buy the necessary
            items in order to survive. The weekend is usually a good time to go
            shopping because people have free time from work. At this time the
            shops are usually very busy because people try to get the best items
            for the lowest price before the item runs out of stock.
          </p>
        </div>

        <div className="col-12 col-md-5">
          <img height={250} alt="hero" src={require("../assets/hero.jpg")} />
        </div>
      </section>
    </main>
  );
}

export default Home;
