import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import usePost from "../utils/usePost";

export default function Createpassword() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const params = useLocation();
  const { pathname, search } = params;
  const token = search.split("=")[1];
  console.log(search.split("=")[1]);

  const { callFunc, loading } = usePost();

  const CreatePasswordAction = () => {
    console.log("first");
    callFunc(
      `${pathname}?token=${token}&email=${email}&password=${password}&password_confirmation=${password}`,
      "POST",
      {}
    );
  };

  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Create New Password</h3>
        </div>
      </div>
      <div className="container d-flex justify-content-center py-5">
        <section className="text-center card rounded col-10 col-md-5 p-5">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              CreatePasswordAction();
            }}
          >
            <div className="mb-4">
              <input
                value={email}
                onChange={(e) => setemail(e.target.value)}
                type="email"
                className="form-control"
                placeholder="Email"
                required
              />
            </div>

            <div className="mb-4">
              <input
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder="Enter New Password"
                required
              />
            </div>

            <div className="mb-4">
              <input
                value={cpassword}
                onChange={(e) => setcpassword(e.target.value)}
                type="password"
                className="form-control"
                placeholder="Confirm New Password"
                required
              />
            </div>

            <div className="d-flex  justify-content-center">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-primary px-5 py-2 w-100"
              >
                {loading ? "Please wait.." : "Submit"}
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
