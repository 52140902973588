import React from "react";

function DashboardCard(props) {
  return (
    <div className="card border-0">
      <div className="p-4 shadow bg-light">
        <h4 className="text-secondary">{props.title}</h4>
        <h2 className="text-success">{props.count}</h2>
      </div>
    </div>
  );
}

export default DashboardCard;
