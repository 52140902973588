import React from "react";
import UserRow from "../../common/UserRow";
import useFetch from "../../utils/useFetch";

function BusinessOwnerProfile() {
  const { data, loading } = useFetch("users");
  const currentUser =
    (data &&
      data.find((x) => String(x.id) === localStorage.getItem("userId"))) ||
    {};
  return (
    <div className="mt-5">
      <UserRow loading={loading} currentUser={currentUser} />
    </div>
  );
}

export default BusinessOwnerProfile;
