import React from "react";

function StudentBlogs() {
  return (
    <div className="container">
      <h3 className="mt-4">Blogs</h3>
      <div className="w-100 border border-dark position-relative iframe-loader posts-height">
        <iframe
          src="https://foded92621haboty.wordpress.com/"
          title="Mercado Escolar Blogs"
          width={"100%"}
          height={"100%"}
        ></iframe>
        <div className="hide-header"></div>
      </div>
    </div>
  );
}

export default StudentBlogs;
