import React from "react";
import Loader from "./Loader";

function UserRow({ loading, currentUser }) {
  return (
    <div className="container mb-5">
      <h3>Profile Details</h3>
      {loading ? (
        <Loader />
      ) : (
        <div className="d-flex flex-wrap mt-4">
          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Full Name</small>
            <h4>{currentUser.name}</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Email</small>
            <h4>{currentUser.email}</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Phone</small>
            <h4>{currentUser.phone}</h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserRow;
