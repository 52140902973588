import React from "react";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import ProductCard from "../../common/ProductCard";
import useFetch from "../../utils/useFetch";
import usePost from "../../utils/usePost";

function BusinessOwnerAds() {
  const { data = [], loading, fetchFunc } = useFetch("ad");
  const [show, setShow] = React.useState(false);
  const userId = localStorage.getItem("userId");
  const { callFunc, loading: postLoading } = usePost();

  React.useEffect(() => {
    return () => {
      setShow(false);
    };
  }, []);

  const AddAdvt = () => {
    const [form, setForm] = React.useState({
      title: "",
      description: "",
      image: "",
    });

    const handleChangeInput = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };

    const handleSubmit = () => {
      if (!form.title || !form.description || !form.image) {
        return;
      }

      callFunc("ad", "POST", { ...form, userId, status: "Active" });
      setForm({
        title: "",
        description: "",
        image: "",
      });
      setShow(false);
      fetchFunc();
    };

    return (
      <div className="col-4">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Advertisement
              </h1>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label for="recipient-name" className="col-form-label">
                    Title:
                  </label>
                  <input
                    value={form.title}
                    onChange={handleChangeInput}
                    name="title"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label for="recipient-name" className="col-form-label">
                    Image URL:
                  </label>
                  <input
                    value={form.image}
                    onChange={handleChangeInput}
                    name="image"
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label for="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    name="description"
                    value={form.description}
                    onChange={handleChangeInput}
                    className="form-control"
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={() => setShow(false)}
                type="button"
                className="btn btn-secondary m-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={postLoading}
                type="button"
                className="btn btn-primary"
              >
                {postLoading ? "Please wait.." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="d-flex justify-content-between">
          <h3>Advertisements</h3>
          <button
            className="btn btn-outline-success"
            onClick={() => setShow(true)}
          >
            + Add Advt
          </button>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="d-flex flex-wrap">
            {show ? (
              <AddAdvt />
            ) : data && data.length > 0 ? (
              data.map((x, i) => (
                <div className="p-4 col-12 col-md-4" key={i}>
                  <ProductCard
                    title={x.title}
                    image={x.image}
                    description={x.description}
                    category="ad"
                    index={i}
                    cost={x.cost}
                    deleteProduct={() => {
                      callFunc(`ad/${x.id}`);
                      fetchFunc();
                    }}
                  />
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default BusinessOwnerAds;
