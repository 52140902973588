import React from "react";
import Loader from "../../common/Loader";
import UsersList from "../../common/UsersList";
import useFetch from "../../utils/useFetch";

function SchoolAdminBusinessOwners() {
  const { data, loading, error, fetchFunc } = useFetch("users");
  if (error) {
    return <h4>Something went wrong, Please try again</h4>;
  }

  const onlybusinessOwners = data
    ? data.filter((x) => x.role === "business_owner")
    : [];

  return (
    <div className="mt-5">
      <div className="container col-8">
        {loading ? (
          <Loader />
        ) : (
          <UsersList
            role="business_owner"
            label={"Business Owners"}
            users={onlybusinessOwners}
            loading={loading}
            fetchFunc={fetchFunc}
          />
        )}
      </div>
    </div>
  );
}

export default SchoolAdminBusinessOwners;
