import React from "react";

function NoData() {
  return (
    <div className="col-12 mt-5 d-flex justify-content-center">
      <img
        src="https://cdn.dribbble.com/users/760295/screenshots/4433975/media/03494b209a1511a61868ced337b97931.png?compress=1&resize=400x300&vertical=top"
        alt="no-data"
      />
    </div>
  );
}

export default NoData;
