import React from "react";

function UsersTable({ data, source, updateStatus }) {
  return (
    <div>
      <table className="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            {!source && <th>Role</th>}
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((x, i) => (
            <tr key={i}>
              <th>{x.name}</th>
              <td>{x.email}</td>
              <td>{x.phone}</td>
              {!source && <td>{x.role}</td>}
              <td>{x.status}</td>
              <td>
                <button
                  onClick={() =>
                    updateStatus(
                      x.id,
                      x.status === "Active" ? "Inactive" : "Active"
                    )
                  }
                  className="btn btn-secondary"
                >
                  Change Status
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersTable;
