import React from "react";
import Loader from "../../common/Loader";
import NoData from "../../common/NoData";
import OrdersTable from "../../common/OrdersTable";
import useFetch from "../../utils/useFetch";

function BusinessOwnerOrders() {
  const { data: orders, loading: OrderLoading } = useFetch("order");
  return (
    <div className="mt-5">
      <div className="container col-11 col-md-8">
        <h3>Orders</h3>
        <div className="mt-4">
          {OrderLoading ? (
            <Loader />
          ) : orders && orders.length > 0 ? (
            <OrdersTable data={orders} />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerOrders;
