import React from "react";

function ProductCard(props) {
  const [count, setCount] = React.useState(1);
  const handleIncrement = () => setCount((prev) => prev + 1);
  const handleDecrement = () => setCount((prev) => (prev > 1 ? prev - 1 : 1));

  const AddToCart = () => {
    return (
      <button
        onClick={() => props.onAddItemtoCart(count)}
        className="btn btn-success"
      >
        + Add To Cart
      </button>
    );
  };

  return (
    <div className="card">
      <div className="text-center">
        <img
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
          alt="sercie"
          src={props.image}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title text-info">{props.title}</h5>
        <p className="card-text mb-1">{props.description}</p>
        {props.cost && <p className="card-text">$ {props.cost}</p>}
        {props.onAddItemtoCart && props.cost && (
          <>
            <div
              className="btn-group d-block mb-3"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                onClick={handleDecrement}
                className="btn btn-light"
              >
                -
              </button>
              <button type="button" className="btn btn-light">
                {count || 0}
              </button>
              <button
                type="button"
                onClick={handleIncrement}
                className="btn btn-light"
              >
                +
              </button>
            </div>
            <AddToCart />
          </>
        )}
        {props.onAddItemtoCart && !props.cost && (
          <div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <button className="btn btn-info" onClick={props.deleteProduct}>
              Learn more
            </button>
          </div>
        )}
        {props.deleteProduct && (
          <button className="btn btn-danger" onClick={props.deleteProduct}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
