export const getNavigation = (role) => {
  if (!role) {
    window.location.href = "/";
  }
  switch (role) {
    case "student":
      window.location.href = "/user";
      break;
    case "business_owner":
      window.location.href = "/business-owner";
      break;
    case "school_admin":
      window.location.href = "/school-admin";
      break;
    case "super_admin":
      window.location.href = "/super-admin";
      break;
    default:
      window.location.href = "/";
  }
};
