import React, { useState } from "react";
import { useParams } from "react-router";
import Loader from "../../common/Loader";
import useFetch from "../../utils/useFetch";
import Select from "react-select";
import usePost from "../../utils/usePost";

const options = [
  { value: "Order Placed", label: "Order Placed" },
  { value: "In Transit", label: "In Transit" },
  { value: "Out for Delivery", label: "Out for Delivery" },
  { value: "Delivered", label: "Delivered" },
  { value: "Cancel", label: "Cancel" },
];

function BusinessOwnerOrderView() {
  const userId = localStorage.getItem("userId");
  const params = useParams();
  const { data, loading, fetchFunc, error } = useFetch(`order/${params.id}`);
  const { callFunc } = usePost();
  const [status, setStatus] = useState(null);

  const changeStatus = () => {
    const body = {
      userId,
      products: JSON.stringify(data.products.map((x) => x.id)),
      quantities: JSON.stringify(data.quantities),
      totalCost: data.totalCost,
      status: status.value,
    };
    callFunc(`order/${params.id}`, "POST", body);
    fetchFunc();
  };

  return (
    <div className="mt-5">
      <div className="container col-6">
        <div className="mt-4">
          {loading ? (
            <Loader />
          ) : (
            data && (
              <div>
                <div className="d-flex justify-content-between mb-4">
                  <h4> Order Details #{params.id}</h4>
                  <h6>{data.created_at && data?.created_at.split("T")[0]}</h6>
                </div>
                {data && <OrderView data={data} />}
                {data && data?.status !== "Delivered" && (
                  <div className="col-12 col-md-4">
                    <h6 className="mt-5">Take Action</h6>
                    <Select
                      options={options}
                      defaultValue={
                        status || { label: data?.status, value: data?.status }
                      }
                      onChange={(e) => setStatus(e)}
                    />
                    <button
                      onClick={changeStatus}
                      disabled={loading}
                      className="btn btn-primary px-5 py-2 w-100 mt-3"
                    >
                      {loading ? "Please wait.." : "Submit"}
                    </button>{" "}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

const OrderView = ({ data }) => {
  if (!data) {
    return "somehthing went wrong";
  }

  return (
    data && (
      <div>
        <div className="d-flex justify-content-between mt-3">
          <div className="col-3">
            <small className="text-secondary">Customer Name</small>
            <p>{data?.user?.name}</p>
          </div>

          <div className=" col-3">
            <small className="text-secondary">Phone</small>
            <p>{data?.user?.phone}</p>
          </div>

          <div className=" col-3">
            <small className="text-secondary">Order Status</small>
            <p>{data?.status}</p>
          </div>
        </div>

        <table className="table table-hover table-responsive mt-4">
          <thead>
            <tr>
              <th scope="col">Item Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Cost</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.products &&
              data.products.map((x, i) => (
                <tr key={i}>
                  <th scope="row">{x.title}</th>
                  <td>
                    {data.quantities[i]} X ${x.cost}
                  </td>
                  <td> $ {data.quantities[i] * x.cost}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="col-11 d-flex justify-content-end">
          Total Cost : ${data.totalCost}
        </div>
      </div>
    )
  );
};

export default BusinessOwnerOrderView;
